import React, {useState, useContext, useEffect, useRef} from "react";
import { BreadcrumbContext } from '../../../components/Breadcrumb';
import { FirebaseAuth } from "../../../components/FirebaseAuth/firebase";
import { Link, Redirect } from 'react-router-dom';
import Loader from '../../../components/Loader';

const Home = () => {
    const title = 'Matomo Instances';

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    

    const [loading, setLoading] = useState(true);
    const [matomos, setMatomos] = useState([]);
    const [moreInstancesAvailable, setMoreInstancesAvailable] = useState(false);
    const mountedRef = useRef(true);
    const [userPaymentData, setUserPaymentData] = useState({});


    const updatePaymentData = (userDoc) => {
        if (userDoc.exists) {
            let result = userDoc.data();
            var ndate = new Date(new Date(result.creationTime.toDate()).setDate(new Date(result.creationTime.toDate()).getDate() + 14));
            result["trialEnds"] = ndate.toDateString();
            result["trialActive"] = false;
            if(ndate > new Date()) {
                result["trialActive"] = true;
            }
            setUserPaymentData(result);
            return result
        }
    }


    // let ref = FirebaseAuth.firestore().collection('users').doc(FirebaseAuth.auth().currentUser.uid).onSnapshot(function(userDoc) {
    //     updatePaymentData(userDoc);
    // });

    const getMatomos = () => {
        setLoading(true);
        let records = [];
        const matomosRef = FirebaseAuth.firestore().collection('matomos');
        let query = matomosRef.where('access', 'array-contains', FirebaseAuth.auth().currentUser.uid);
        query.get().then(matomoSnapshots => {
            if (!mountedRef.current) return null;
            matomoSnapshots.forEach(matomo => {
                records.push({
                    'id': matomo.id,
                    'name': matomo.data().name,
                    'host': matomo.data().host,
                    'subscriptionStatus': matomo.data().subscriptionStatus
                });
            });
            setMatomos(records);
            setLoading(false);
        });
    };

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        getMatomos();

        FirebaseAuth.firestore().collection('users').doc(FirebaseAuth.auth().currentUser.uid).onSnapshot(function(userDoc) {
            updatePaymentData(userDoc);
        });

        return () => {
            mountedRef.current = false
        }
    },[setBreadcrumb]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    
                    {matomos.length > 0 ? (
                        <>
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md">
                                            Subscription Status: {(() => {
                                                let sumActiveInstances = matomos.length;
                                                let sumAvailableInstances = (parseInt(userPaymentData.subscriptionInstanceCount) || 0) + (parseInt(userPaymentData.freeInstances) || 0);
                                                let freeString = "";
                                                if((parseInt(userPaymentData.freeInstances) || 0)>0) {
                                                    freeString = " - inclusive " + userPaymentData.freeInstances + " free instances";
                                                }
                                                if(userPaymentData.subscriptionStatus === "active" && sumActiveInstances > sumAvailableInstances) {
                                                    return "Check Subscription - More instances active than available("+sumActiveInstances+" used from "+sumAvailableInstances+" instances "+freeString+")";
                                                }
                                                else if(userPaymentData.subscriptionStatus === "active") {
                                                    if(sumActiveInstances < sumAvailableInstances) {
                                                        if(!moreInstancesAvailable) {
                                                            setMoreInstancesAvailable(true)
                                                        }
                                                    }
                                                    return "Subscription active ("+sumActiveInstances+" used from "+sumAvailableInstances+" instances "+freeString+")";
                                                }
                                                else if(userPaymentData.freeInstances && userPaymentData.freeInstances > 0) {
                                                    if(sumActiveInstances < userPaymentData.freeInstances) {
                                                        if(!moreInstancesAvailable) {
                                                            setMoreInstancesAvailable(true)
                                                        }
                                                    }
                                                    return "Free instance ("+sumActiveInstances+" used from " + userPaymentData.freeInstances + " free instances)";
                                                }
                                                else if(userPaymentData.trialActive) {
                                                    if(sumActiveInstances < 1) {
                                                        if(!moreInstancesAvailable) {
                                                            setMoreInstancesAvailable(true)
                                                        }
                                                    }
                                                    return "Trial active until " + userPaymentData.trialEnds + " ("+sumActiveInstances+" used from 1 matomo instances)";
                                                }
                                                else {
                                                    return "Trial expired - upgrade subscription";
                                                }



                                            })()}
                                        </div>
                                        <div className="col-sm text-right">
                                            {
                                                !moreInstancesAvailable
                                                    ? <Link to="/user/billing" className="btn btn-primary">Upgrade Subscription to create more instances</Link>
                                                    : <Link to="/new-matomo" className="btn btn-primary"><i className="fa fa-plus"></i> Add Instance</Link>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {matomos.map((matomo, i) =>
                                
                                <div className="card" key={matomo.id}>
                                    <div className="card-header font-weight-bold">
                                        <h5>{matomo.name}</h5>
                                    </div>
                                    <div className="card-body">
                                        <p><b>Matomo Instance host: {matomo.host}</b></p>
                                        <Link to={'/matomo/'+matomo.id+'/'} className="btn btn-primary mr-3">View</Link>
                                        <Link to={'/matomo/'+matomo.id+'/update'} className="btn btn-primary mr-3">Edit</Link>
                                        {/*<Link to={'/matomo/'+matomo.id+'/users'} className="btn btn-primary mr-3">Manage Users</Link>*/}
                                    </div>
                                </div>
                                
                            )}

                            <div className="card">
                                <div className="card-header font-weight-bold">
                                    <h5>Quickstart</h5>
                                </div>
                                <div className="card-body">
                                    <p><b>Follow this steps to create a Matomo Data Studio Datasource:</b></p>
                                    <ul>
                                     <li>Search for our Matomo Data Studio Connector in the <a target='_blank' rel="noopener noreferrer" href="https://datastudio.google.com/data?search=Matomo%20Analytics">Connector Partner Gallery</a> or open our Matomo Data Studio Connector <a target='_blank' rel="noopener noreferrer" href='https://datastudio.google.com/datasources/create?connectorId=AKfycbzBEugnya2Ndrdua-9xT32MXOJFnxx2poPiOXzJl8LrATZ7bOcaROJfPYL4dyKxqJlS'>here</a></li>
                                    <li>Login with the VIU.one credentials</li>
                                    <li>Choose Matomo instance from drop down</li>
                                    <li>Choose Report from drop down</li>
                                    <li>Click create</li>
                                    </ul>
                                    <p>
                                    Repeat steps for each report you want to add to your Data Studio Dashboard
                                    </p>
                                    <p>
                                    <a target='_blank' rel="noopener noreferrer"  href='https://viu.one/matomo-datastudio-connector/'>more information</a>
                                    </p>
                                </div>
                            </div>


                        </>
                    ) : (
                        <>
                            {(loading) ? (
                                <Loader text="loading matomos..."></Loader>
                            ):(
                                <Redirect to="/new-matomo"></Redirect>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>

    )
}

export default Home;
