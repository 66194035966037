import React, {useContext, useEffect, useRef, useState} from "react";
import {CloudFunctions, FirebaseAuth} from "../../../../components/FirebaseAuth/firebase";
import {AuthContext} from "../../../../components/FirebaseAuth";
import {BreadcrumbContext} from '../../../../components/Breadcrumb';
import Loader from '../../../../components/Loader';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import Alert from "../../../../components/Alert";
import {Field, Form, Input} from "../../../../components/Form";
import {
    checkVAT, germany, austria, belgium, bulgaria, cyprus, czechRepublic, denmark, estonia, finland, france,
    greece, hungary, ireland, italy, latvia, lithuania, luxembourg, malta, netherlands, poland, portugal, romania,
    slovakiaRepublic, slovenia, spain, sweden
} from 'jsvat';
import PublicTemplate from "../../../../components/templates/PublicTemplate";

const Plans = () => {
    const title = 'Select a Plan';

    const [planLoading, setPlanLoading] = useState(true);

    const userLang = navigator.language;
    const stripeCountryCodes = [
        { code: "AD", country: "Andorra", phone: "376" },
        {
            code: "AE",
            country: "United Arab Emirates",
            phone: "971"
        },
        { code: "AF", country: "Afghanistan", phone: "93" },
        {
            code: "AG",
            country: "Antigua and Barbuda",
            phone: "1-268"
        },
        { code: "AI", country: "Anguilla", phone: "1-264" },
        { code: "AL", country: "Albania", phone: "355" },
        { code: "AM", country: "Armenia", phone: "374" },
        { code: "AO", country: "Angola", phone: "244" },
        { code: "AQ", country: "Antarctica", phone: "672" },
        { code: "AR", country: "Argentina", phone: "54" },
        { code: "AS", country: "American Samoa", phone: "1-684" },
        { code: "AT", country: "Austria", phone: "43", vatCheck: austria },
        {
            code: "AU",
            country: "Australia",
            phone: "61",
            "states": [
                {code: "NSW", country: "New South Wales"},
                {code: "QLD", country: "Queensland"},
                {code: "SA", country: "South Australia"},
                {code: "TAS", country: "Tasmania"},
                {code: "VIC", country: "Victoria"},
                {code: "WA", country: "Western Australia"},
                {code: "ACT", country: "Australian Capital Territory"},
                {code: "NT", country: "Northern Territory"}
            ],
            "suggested": true
        },
        { code: "AW", country: "Aruba", phone: "297" },
        { code: "AX", country: "Alland Islands", phone: "358" },
        { code: "AZ", country: "Azerbaijan", phone: "994" },
        {
            code: "BA",
            country: "Bosnia and Herzegovina",
            phone: "387"
        },
        { code: "BB", country: "Barbados", phone: "1-246" },
        { code: "BD", country: "Bangladesh", phone: "880" },
        { code: "BE", country: "Belgium", phone: "32", vatCheck: belgium },
        { code: "BF", country: "Burkina Faso", phone: "226" },
        { code: "BG", country: "Bulgaria", phone: "359", vatCheck: bulgaria },
        { code: "BH", country: "Bahrain", phone: "973" },
        { code: "BI", country: "Burundi", phone: "257" },
        { code: "BJ", country: "Benin", phone: "229" },
        { code: "BL", country: "Saint Barthelemy", phone: "590" },
        { code: "BM", country: "Bermuda", phone: "1-441" },
        { code: "BN", country: "Brunei Darussalam", phone: "673" },
        { code: "BO", country: "Bolivia", phone: "591" },
        { code: "BR", country: "Brazil", phone: "55" },
        { code: "BS", country: "Bahamas", phone: "1-242" },
        { code: "BT", country: "Bhutan", phone: "975" },
        { code: "BV", country: "Bouvet Island", phone: "47" },
        { code: "BW", country: "Botswana", phone: "267" },
        { code: "BY", country: "Belarus", phone: "375" },
        { code: "BZ", country: "Belize", phone: "501" },
        {
            code: "CA",
            country: "Canada",
            phone: "1",
            "suggested": true
        },
        {
            code: "CC",
            country: "Cocos (Keeling) Islands",
            phone: "61"
        },
        {
            code: "CD",
            country: "Congo, Democratic Republic of the",
            phone: "243"
        },
        {
            code: "CF",
            country: "Central African Republic",
            phone: "236"
        },
        {
            code: "CG",
            country: "Congo, Republic of the",
            phone: "242"
        },
        { code: "CH", country: "Switzerland", phone: "41" },
        { code: "CI", country: "Cote d'Ivoire", phone: "225" },
        { code: "CK", country: "Cook Islands", phone: "682" },
        { code: "CL", country: "Chile", phone: "56" },
        { code: "CM", country: "Cameroon", phone: "237" },
        { code: "CN", country: "China", phone: "86" },
        { code: "CO", country: "Colombia", phone: "57" },
        { code: "CR", country: "Costa Rica", phone: "506" },
        { code: "CU", country: "Cuba", phone: "53" },
        { code: "CV", country: "Cape Verde", phone: "238" },
        { code: "CW", country: "Curacao", phone: "599" },
        { code: "CX", country: "Christmas Island", phone: "61" },
        { code: "CY", country: "Cyprus", phone: "357", vatCheck: cyprus },
        { code: "CZ", country: "Czech Republic", phone: "420", vatCheck: czechRepublic },
        {
            code: "DE",
            country: "Germany",
            phone: "49",
            "suggested": true, vatCheck: germany
        },
        { code: "DJ", country: "Djibouti", phone: "253" },
        { code: "DK", country: "Denmark", phone: "45", vatCheck: denmark },
        { code: "DM", country: "Dominica", phone: "1-767" },
        {
            code: "DO",
            country: "Dominican Republic",
            phone: "1-809"
        },
        { code: "DZ", country: "Algeria", phone: "213" },
        { code: "EC", country: "Ecuador", phone: "593" },
        { code: "EE", country: "Estonia", phone: "372", vatCheck: estonia },
        { code: "EG", country: "Egypt", phone: "20" },
        { code: "EH", country: "Western Sahara", phone: "212" },
        { code: "ER", country: "Eritrea", phone: "291" },
        { code: "ES", country: "Spain", phone: "34", vatCheck: spain },
        { code: "ET", country: "Ethiopia", phone: "251" },
        { code: "FI", country: "Finland", phone: "358", vatCheck: finland },
        { code: "FJ", country: "Fiji", phone: "679" },
        {
            code: "FK",
            country: "Falkland Islands (Malvinas)",
            phone: "500"
        },
        {
            code: "FM",
            country: "Micronesia, Federated States of",
            phone: "691"
        },
        { code: "FO", country: "Faroe Islands", phone: "298" },
        {
            code: "FR",
            country: "France",
            phone: "33",
            "suggested": true, vatCheck: france
        },
        { code: "GA", country: "Gabon", phone: "241" },
        { code: "GB", country: "United Kingdom", phone: "44" },
        { code: "GD", country: "Grenada", phone: "1-473" },
        { code: "GE", country: "Georgia", phone: "995" },
        { code: "GF", country: "French Guiana", phone: "594" },
        { code: "GG", country: "Guernsey", phone: "44" },
        { code: "GH", country: "Ghana", phone: "233" },
        { code: "GI", country: "Gibraltar", phone: "350" },
        { code: "GL", country: "Greenland", phone: "299" },
        { code: "GM", country: "Gambia", phone: "220" },
        { code: "GN", country: "Guinea", phone: "224" },
        { code: "GP", country: "Guadeloupe", phone: "590" },
        { code: "GQ", country: "Equatorial Guinea", phone: "240" },
        { code: "GR", country: "Greece", phone: "30", vatCheck: greece },
        {
            code: "GS",
            country: "South Georgia and the South Sandwich Islands",
            phone: "500"
        },
        { code: "GT", country: "Guatemala", phone: "502" },
        { code: "GU", country: "Guam", phone: "1-671" },
        { code: "GW", country: "Guinea-Bissau", phone: "245" },
        { code: "GY", country: "Guyana", phone: "592" },
        { code: "HK", country: "Hong Kong", phone: "852" },
        {
            code: "HM",
            country: "Heard Island and McDonald Islands",
            phone: "672"
        },
        { code: "HN", country: "Honduras", phone: "504" },
        { code: "HR", country: "Croatia", phone: "385" },
        { code: "HT", country: "Haiti", phone: "509" },
        { code: "HU", country: "Hungary", phone: "36", vatCheck: hungary },
        { code: "ID", country: "Indonesia", phone: "62" },
        { code: "IE", country: "Ireland", phone: "353", vatCheck: ireland },
        { code: "IL", country: "Israel", phone: "972" },
        { code: "IM", country: "Isle of Man", phone: "44" },
        { code: "IN", country: "India", phone: "91" },
        {
            code: "IO",
            country: "British Indian Ocean Territory",
            phone: "246"
        },
        { code: "IQ", country: "Iraq", phone: "964" },
        {
            code: "IR",
            country: "Iran, Islamic Republic of",
            phone: "98"
        },
        { code: "IS", country: "Iceland", phone: "354" },
        { code: "IT", country: "Italy", phone: "39", vatCheck: italy },
        { code: "JE", country: "Jersey", phone: "44" },
        { code: "JM", country: "Jamaica", phone: "1-876" },
        { code: "JO", country: "Jordan", phone: "962" },
        {
            code: "JP",
            country: "Japan",
            phone: "81",
            "suggested": true
        },
        { code: "KE", country: "Kenya", phone: "254" },
        { code: "KG", country: "Kyrgyzstan", phone: "996" },
        { code: "KH", country: "Cambodia", phone: "855" },
        { code: "KI", country: "Kiribati", phone: "686" },
        { code: "KM", country: "Comoros", phone: "269" },
        {
            code: "KN",
            country: "Saint Kitts and Nevis",
            phone: "1-869"
        },
        {
            code: "KP",
            country: "Korea, Democratic People's Republic of",
            phone: "850"
        },
        { code: "KR", country: "Korea, Republic of", phone: "82" },
        { code: "KW", country: "Kuwait", phone: "965" },
        { code: "KY", country: "Cayman Islands", phone: "1-345" },
        { code: "KZ", country: "Kazakhstan", phone: "7" },
        {
            code: "LA",
            country: "Lao People's Democratic Republic",
            phone: "856"
        },
        { code: "LB", country: "Lebanon", phone: "961" },
        { code: "LC", country: "Saint Lucia", phone: "1-758" },
        { code: "LI", country: "Liechtenstein", phone: "423" },
        { code: "LK", country: "Sri Lanka", phone: "94" },
        { code: "LR", country: "Liberia", phone: "231" },
        { code: "LS", country: "Lesotho", phone: "266" },
        { code: "LT", country: "Lithuania", phone: "370", vatCheck: lithuania },
        { code: "LU", country: "Luxembourg", phone: "352", vatCheck: luxembourg },
        { code: "LV", country: "Latvia", phone: "371", vatCheck: latvia },
        { code: "LY", country: "Libya", phone: "218" },
        { code: "MA", country: "Morocco", phone: "212" },
        { code: "MC", country: "Monaco", phone: "377" },
        {
            code: "MD",
            country: "Moldova, Republic of",
            phone: "373"
        },
        { code: "ME", country: "Montenegro", phone: "382" },
        {
            code: "MF",
            country: "Saint Martin (French part)",
            phone: "590"
        },
        { code: "MG", country: "Madagascar", phone: "261" },
        { code: "MH", country: "Marshall Islands", phone: "692" },
        {
            code: "MK",
            country: "Macedonia, the Former Yugoslav Republic of",
            phone: "389"
        },
        { code: "ML", country: "Mali", phone: "223" },
        { code: "MM", country: "Myanmar", phone: "95" },
        { code: "MN", country: "Mongolia", phone: "976" },
        { code: "MO", country: "Macao", phone: "853" },
        {
            code: "MP",
            country: "Northern Mariana Islands",
            phone: "1-670"
        },
        { code: "MQ", country: "Martinique", phone: "596" },
        { code: "MR", country: "Mauritania", phone: "222" },
        { code: "MS", country: "Montserrat", phone: "1-664" },
        { code: "MT", country: "Malta", phone: "356", vatCheck: malta },
        { code: "MU", country: "Mauritius", phone: "230" },
        { code: "MV", country: "Maldives", phone: "960" },
        { code: "MW", country: "Malawi", phone: "265" },
        { code: "MX", country: "Mexico", phone: "52" },
        { code: "MY", country: "Malaysia", phone: "60" },
        { code: "MZ", country: "Mozambique", phone: "258" },
        { code: "NA", country: "Namibia", phone: "264" },
        { code: "NC", country: "New Caledonia", phone: "687" },
        { code: "NE", country: "Niger", phone: "227" },
        { code: "NF", country: "Norfolk Island", phone: "672" },
        { code: "NG", country: "Nigeria", phone: "234" },
        { code: "NI", country: "Nicaragua", phone: "505" },
        { code: "NL", country: "Netherlands", phone: "31", vatCheck: netherlands },
        { code: "NO", country: "Norway", phone: "47" },
        { code: "NP", country: "Nepal", phone: "977" },
        { code: "NR", country: "Nauru", phone: "674" },
        { code: "NU", country: "Niue", phone: "683" },
        { code: "NZ", country: "New Zealand", phone: "64" },
        { code: "OM", country: "Oman", phone: "968" },
        { code: "PA", country: "Panama", phone: "507" },
        { code: "PE", country: "Peru", phone: "51" },
        { code: "PF", country: "French Polynesia", phone: "689" },
        { code: "PG", country: "Papua New Guinea", phone: "675" },
        { code: "PH", country: "Philippines", phone: "63" },
        { code: "PK", country: "Pakistan", phone: "92" },
        { code: "PL", country: "Poland", phone: "48", vatCheck: poland },
        {
            code: "PM",
            country: "Saint Pierre and Miquelon",
            phone: "508"
        },
        { code: "PN", country: "Pitcairn", phone: "870" },
        { code: "PR", country: "Puerto Rico", phone: "1" },
        {
            code: "PS",
            country: "Palestine, State of",
            phone: "970"
        },
        { code: "PT", country: "Portugal", phone: "351", vatCheck: portugal },
        { code: "PW", country: "Palau", phone: "680" },
        { code: "PY", country: "Paraguay", phone: "595" },
        { code: "QA", country: "Qatar", phone: "974" },
        { code: "RE", country: "Reunion", phone: "262" },
        { code: "RO", country: "Romania", phone: "40", vatCheck: romania },
        { code: "RS", country: "Serbia", phone: "381" },
        { code: "RU", country: "Russian Federation", phone: "7" },
        { code: "RW", country: "Rwanda", phone: "250" },
        { code: "SA", country: "Saudi Arabia", phone: "966" },
        { code: "SB", country: "Solomon Islands", phone: "677" },
        { code: "SC", country: "Seychelles", phone: "248" },
        { code: "SD", country: "Sudan", phone: "249" },
        { code: "SE", country: "Sweden", phone: "46", vatCheck: sweden },
        { code: "SG", country: "Singapore", phone: "65" },
        { code: "SH", country: "Saint Helena", phone: "290" },
        { code: "SI", country: "Slovenia", phone: "386", vatCheck: slovenia },
        {
            code: "SJ",
            country: "Svalbard and Jan Mayen",
            phone: "47"
        },
        { code: "SK", country: "Slovakia", phone: "421", vatCheck: slovakiaRepublic },
        { code: "SL", country: "Sierra Leone", phone: "232" },
        { code: "SM", country: "San Marino", phone: "378" },
        { code: "SN", country: "Senegal", phone: "221" },
        { code: "SO", country: "Somalia", phone: "252" },
        { code: "SR", country: "Suriname", phone: "597" },
        { code: "SS", country: "South Sudan", phone: "211" },
        {
            code: "ST",
            country: "Sao Tome and Principe",
            phone: "239"
        },
        { code: "SV", country: "El Salvador", phone: "503" },
        {
            code: "SX",
            country: "Sint Maarten (Dutch part)",
            phone: "1-721"
        },
        {
            code: "SY",
            country: "Syrian Arab Republic",
            phone: "963"
        },
        { code: "SZ", country: "Swaziland", phone: "268" },
        {
            code: "TC",
            country: "Turks and Caicos Islands",
            phone: "1-649"
        },
        { code: "TD", country: "Chad", phone: "235" },
        {
            code: "TF",
            country: "French Southern Territories",
            phone: "262"
        },
        { code: "TG", country: "Togo", phone: "228" },
        { code: "TH", country: "Thailand", phone: "66" },
        { code: "TJ", country: "Tajikistan", phone: "992" },
        { code: "TK", country: "Tokelau", phone: "690" },
        { code: "TL", country: "Timor-Leste", phone: "670" },
        { code: "TM", country: "Turkmenistan", phone: "993" },
        { code: "TN", country: "Tunisia", phone: "216" },
        { code: "TO", country: "Tonga", phone: "676" },
        { code: "TR", country: "Turkey", phone: "90" },
        {
            code: "TT",
            country: "Trinidad and Tobago",
            phone: "1-868"
        },
        { code: "TV", country: "Tuvalu", phone: "688" },
        {
            code: "TW",
            country: "Taiwan, Province of China",
            phone: "886"
        },
        {
            code: "TZ",
            country: "United Republic of Tanzania",
            phone: "255"
        },
        { code: "UA", country: "Ukraine", phone: "380" },
        { code: "UG", country: "Uganda", phone: "256" },
        {
            code: "US",
            country: "United States",
            phone: "1",
            "states": [
                {code:"AL", country:"Alabama"},
                {code:"AK", country:"Alaska"},
                {code:"AS", country:"American Samoa"},
                {code:"AZ", country:"Arizona"},
                {code:"AR", country:"Arkansas"},
                {code:"CA", country:"California"},
                {code:"CO", country:"Colorado"},
                {code:"CT", country:"Connecticut"},
                {code:"DE", country:"Delaware"},
                {code:"DC", country:"District Of Columbia"},
                {code:"FM", country:"Federated States Of Micronesia"},
                {code:"FL", country:"Florida"},
                {code:"GA", country:"Georgia"},
                {code:"GU", country:"Guam"},
                {code:"HI", country:"Hawaii"},
                {code:"ID", country:"Idaho"},
                {code:"IL", country:"Illinois"},
                {code:"IN", country:"Indiana"},
                {code:"IA", country:"Iowa"},
                {code:"KS", country:"Kansas"},
                {code:"KY", country:"Kentucky"},
                {code:"LA", country:"Louisiana"},
                {code:"ME", country:"Maine"},
                {code:"MH", country:"Marshall Islands"},
                {code:"MD", country:"Maryland"},
                {code:"MA", country:"Massachusetts"},
                {code:"MI", country:"Michigan"},
                {code:"MN", country:"Minnesota"},
                {code:"MS", country:"Mississippi"},
                {code:"MO", country:"Missouri"},
                {code:"MT", country:"Montana"},
                {code:"NE", country:"Nebraska"},
                {code:"NV", country:"Nevada"},
                {code:"NH", country:"New Hampshire"},
                {code:"NJ", country:"New Jersey"},
                {code:"NM", country:"New Mexico"},
                {code:"NY", country:"New York"},
                {code:"NC", country:"North Carolina"},
                {code:"ND", country:"North Dakota"},
                {code:"MP", country:"Northern Mariana Islands"},
                {code:"OH", country:"Ohio"},
                {code:"OK", country:"Oklahoma"},
                {code:"OR", country:"Oregon"},
                {code:"PW", country:"Palau"},
                {code:"PA", country:"Pennsylvania"},
                {code:"PR", country:"Puerto Rico"},
                {code:"RI", country:"Rhode Island"},
                {code:"SC", country:"South Carolina"},
                {code:"SD", country:"South Dakota"},
                {code:"TN", country:"Tennessee"},
                {code:"TX", country:"Texas"},
                {code:"UT", country:"Utah"},
                {code:"VT", country:"Vermont"},
                {code:"VI", country:"Virgin Islands"},
                {code:"VA", country:"Virginia"},
                {code:"WA", country:"Washington"},
                {code:"WV", country:"West Virginia"},
                {code:"WI", country:"Wisconsin"},
                {code:"WY", country:"Wyoming"}
            ],
            "suggested": true
        },
        { code: "UY", country: "Uruguay", phone: "598" },
        { code: "UZ", country: "Uzbekistan", phone: "998" },
        {
            code: "VA",
            country: "Holy See (Vatican City State)",
            phone: "379"
        },
        {
            code: "VC",
            country: "Saint Vincent and the Grenadines",
            phone: "1-784"
        },
        { code: "VE", country: "Venezuela", phone: "58" },
        {
            code: "VG",
            country: "British Virgin Islands",
            phone: "1-284"
        },
        {
            code: "VI",
            country: "US Virgin Islands",
            phone: "1-340"
        },
        { code: "VN", country: "Vietnam", phone: "84" },
        { code: "VU", country: "Vanuatu", phone: "678" },
        { code: "WF", country: "Wallis and Futuna", phone: "681" },
        { code: "WS", country: "Samoa", phone: "685" },
        { code: "XK", country: "Kosovo", phone: "383" },
        { code: "YE", country: "Yemen", phone: "967" },
        { code: "YT", country: "Mayotte", phone: "262" },
        { code: "ZA", country: "South Africa", phone: "27" },
        { code: "ZM", country: "Zambia", phone: "260" },
        { code: "ZW", country: "Zimbabwe", phone: "263" }
    ];

    const reverseChargeCodesEu = [
        'AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'GR', 'HU', 'IE', 'IT',
        'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
    ];

    const {userData} = useContext(AuthContext);
    const stripe = useStripe();
    const elements = useElements();
    const mountedRef = useRef(true);
    const {setBreadcrumb} = useContext(BreadcrumbContext);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }

            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        },
        hidePostalCode: true
    };

    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState({id: 0});
    const [cardError, setCardError] = useState(null);
    const [cardClientSecret, setCardClientSecret] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [inSubmit, setInSubmit] = useState(false);

    const [email, setEmail] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [phone, setPhone] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [name, setName] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [city, setCity] = useState({
        hasError: false,
        error: null,
        value: ""
    });


    const [country, setCountry] = useState('');
    const changeCountry = (event) => {
        setCountry(event.target.value)
        checkVat(vatId.value, event.target.value)
    };

    const [line1, setLine1] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [line2, setLine2] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [postalCode, setPostalCode] = useState({
        hasError: false,
        error: null,
        value: ""
    });
    const [vatIsValid, setVatIsValid] = useState(false);
    const [finalVatId, setFinalVatId] = useState('');
    const [vatId, setVatId] = useState({
        hasError: false,
        error: null,
        value: ""
    });

    const checkVat = (checkVatId, checkCountry) => {
        if (checkCountry) {
            var stripeCountries = stripeCountryCodes.filter(obj => {
                return obj.code === checkCountry
            });
            if (stripeCountries.length > 0) {
                let stripeCountry = stripeCountries[0];
                console.log(stripeCountry)
                console.log(stripeCountry.vatCheck)
                if (stripeCountry.vatCheck !== undefined) {
                    console.log("change: " + checkVatId)
                    console.log(checkVAT(checkVatId, [stripeCountry.vatCheck]));
                    console.log([stripeCountry.vatCheck]);
                    setVatIsValid(checkVAT(checkVatId, [stripeCountry.vatCheck]).isValid);
                    if (checkVAT(checkVatId, [stripeCountry.vatCheck]).isValid) {
                        setFinalVatId(checkVAT(checkVatId, [stripeCountry.vatCheck]).value)
                    }
                } else {
                    setVatIsValid(true);
                }
            }

        } else {
            setVatIsValid(true);
        }

    };

    const changeVatId = (event) => {
        setVatId({value: event.value})
        checkVat(event.value, country)
    };

    useEffect(() => {
        console.log(userData.currentMatomo);
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        setLoading(true);
        let p = [];
        const collectionRef = FirebaseAuth.firestore().collection('plans');
        let query = collectionRef.orderBy('price', 'asc');
        query.get().then(documentSnapshots => {
            if (!mountedRef.current) return null
            documentSnapshots.forEach(doc => {
                p.push({
                    'id': doc.id,
                    'name': doc.data().name,
                    'price': doc.data().price,
                    'currency': doc.data().currency,
                    'paymentCycle': doc.data().paymentCycle,
                    'features': doc.data().features,
                    'stripePriceId': doc.data().stripePriceId
                });
            });
            if (p.length > 0) {
                setPlans(p);
            }
            setLoading(false);
        });
        console.log(userLang)
        if (userLang === "de") {
            setCountry("DE")
        }
        return () => {
            mountedRef.current = false
        }
    }, [userData, setBreadcrumb, title, userLang]);


    const subcribe = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setErrorMessage(null);

        let hasError = false;
        let setupIntentId = '';
        let paymentMethodId = '';

        if (selectedPlan.price !== 0) {
            setCardError(null);

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            let phone_value = undefined;
            if (phone.value) {
                phone_value = phone.value;
            }
            let line2_value = undefined;
            if (line2.value) {
                line2_value = line2.value;
            }
            // Use your card Element with other Stripe.js APIs
            const result = await stripe.confirmCardSetup(cardClientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: name.value,
                    }
                }
            });

            if (result.error) {
                setCardError(result.error.message);
                setInSubmit(false);
                hasError = true;
            } else {
                console.log(result)
                setupIntentId = result.setupIntent.id;
                paymentMethodId = result.setupIntent.payment_method
            }
        }

        if (!hasError) {
            const createSubscription = CloudFunctions.httpsCallable('createSubscription');
            createSubscription({
                planId: selectedPlan.id,
                setupIntentId: setupIntentId,
                paymentMethodId: paymentMethodId,
                customerData: {
                    "name": name.value,
                    "city": city.value,
                    "postalCode": postalCode.value,
                    "country": country,
                    "line1": line1.value,
                    "line2": line2.value,
                    "phone": phone.value,
                    "email": email.value,
                    "vatId": finalVatId,
                    "reverseCharge": (reverseChargeCodesEu.includes(country) && vatIsValid)
                }
            }).then(res => {
                // physical page load to reload the matomo data
                setInSubmit(false);
                document.location = '/';
            }).catch(err => {
                setProcessing(false);
                setErrorMessage(err.message);
                setInSubmit(false);
            });
        } else {
            setProcessing(false);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    {plans.length > 0 ? (
                        <>
                            <div className="card-deck mb-3">
                                <div className="card">
                                    <div className="card-header text-center">{title}</div>
                                    <div className="card-body">
                                        {errorMessage !== null &&
                                        <Alert type="danger" message={errorMessage} dismissible={true}
                                               onDismiss={() => setErrorMessage(null)}></Alert>
                                        }
                                        <div className="row justify-content-md-center">
                                            <div className="col col-sm-12 col-md-10 col-lg-10 col-xl-8">

                                                <div className="card-deck mb-3 text-center">
                                                    {plans.map((plan, i) =>
                                                        <div className="card" key={i + plan.id}>
                                                            <div className="card-header">
                                                                <h4 className="my-0 font-weight-normal">
                                                                    {plan.name}
                                                                </h4>
                                                                <h1 className="card-title">
                                                                    € {plan.price / 100}
                                                                    <small className="text-muted">
                                                                        /{plan.paymentCycle}
                                                                    </small>
                                                                </h1>
                                                            </div>
                                                            <div className="card-body">
                                                                <ul className="list-unstyled mt-3 mb-4">
                                                                    {plan.features.map((feature, i) =>
                                                                        <li key={i}><i
                                                                            className="fa fa-check text-success"></i> {feature}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </div>
                                                            <div className="card-footer bg-white">
                                                                {plan.current ? (
                                                                    <button type="button"
                                                                            className="btn btn-block btn-secondary"
                                                                            disabled={true}>Current Plan</button>
                                                                ) : (
                                                                    <button type="button"
                                                                            className={(plan.id !== selectedPlan.id) ? "btn btn-block btn-outline-success" : "btn btn-block btn-success"}
                                                                            onClick={() => {
                                                                                let actPlanId = undefined
                                                                                for (let i = 0; i < plans.length; i++) {
                                                                                    if (plans[i].id === plan.id) {
                                                                                        setSelectedPlan(plan);
                                                                                        actPlanId = plans[i].id;
                                                                                    }
                                                                                }
                                                                                if (actPlanId) {
                                                                                    const getSetupIntent = CloudFunctions.httpsCallable('getSetupIntent');
                                                                                    setPlanLoading(true)
                                                                                    getSetupIntent({
                                                                                        planId: actPlanId
                                                                                    }).then(res => {
                                                                                        setCardClientSecret(res.data.client_secret)
                                                                                        setPlanLoading(false)

                                                                                    }).catch(err => {
                                                                                        setPlanLoading(false)
                                                                                    });
                                                                                }
                                                                            }}>{plan.id === selectedPlan.id && <><i
                                                                        className="fa fa-check"></i> </>}{(plan.id !== selectedPlan.id) ? "Select" : "Selected"}</button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )
                                                    }
                                                </div>
                                                {selectedPlan.id !== 0 && selectedPlan.price > 0 &&
                                                <div>
                                                    {planLoading ? (
                                                        <PublicTemplate>
                                                            <Loader size="5x" text="Loading..."/>
                                                        </PublicTemplate>
                                                    ) : (
                                                        <Form handleSubmit={e => {
                                                            e.preventDefault();

                                                            setInSubmit(true);
                                                            setErrorMessage(null);
                                                            if (name.value === "") {
                                                                setErrorMessage("Name required");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,16}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

                                                            if (email.value === "") {
                                                                setErrorMessage("Email required");
                                                                setInSubmit(false);
                                                                return false;
                                                            } else if (!pattern.test(email.value)) {
                                                                setErrorMessage("Email is not valid");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            if (line1.value === "") {
                                                                setErrorMessage("Address line 1 required");
                                                                setInSubmit(false);
                                                                return false;
                                                            }

                                                            if (country === "Select Country") {
                                                                setErrorMessage("Country required");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            if (postalCode.value === "") {
                                                                setErrorMessage("Postal code required");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            if (city.value === "") {
                                                                setErrorMessage("City required");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            if (vatId.value !== "" && !vatIsValid) {
                                                                setErrorMessage("VAT ID not valid");
                                                                setInSubmit(false);
                                                                return false;
                                                            }
                                                            subcribe(e);
                                                        }}
                                                              disabled={(selectedPlan.id === 0) || name.hasError || name.value === null || email.hasError || email.value === null || inSubmit}
                                                              inSubmit={inSubmit}
                                                              submitBtnStyle="block btn-primary ml-5"
                                                              submitBtnText="Subscribe"
                                                              enableDefaultButtons={true}>
                                                            <div>
                                                                <div className="card-deck">
                                                                    <div className="card mb-4">
                                                                        <div className="card-header text-center">
                                                                            Billing information
                                                                        </div>
                                                                        <div className="card-body">
                                                                            {errorMessage !== null &&
                                                                            <Alert type="danger"
                                                                                   message={errorMessage}
                                                                                   dismissible={true}
                                                                                   onDismiss={() => setErrorMessage(null)}></Alert>
                                                                            }
                                                                            <div className="row">
                                                                                <div className="col-sm">
                                                                                    <Field label="Company / Name"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="name"
                                                                                               value={name.value}
                                                                                               required={true}
                                                                                               autoComplete="name"
                                                                                               changeHandler={setName}/>
                                                                                    </Field>
                                                                                    <Field label="Address line 1"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="line1"
                                                                                               value={line1.value}
                                                                                               required
                                                                                               autoComplete="line1"
                                                                                               changeHandler={setLine1}/>
                                                                                    </Field>
                                                                                    <Field label="Postal code"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="postalCode"
                                                                                               value={postalCode.value}
                                                                                               required={true}
                                                                                               autoComplete="postalCode"
                                                                                               changeHandler={setPostalCode}/>
                                                                                    </Field>
                                                                                    <Field label="Country"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <select
                                                                                            className="custom-select"
                                                                                            name="country"
                                                                                            required={true}
                                                                                            value={country}
                                                                                            onChange={changeCountry}>
                                                                                            <option>Select Country
                                                                                            </option>
                                                                                            {stripeCountryCodes.map((stripeCountry, i) =>
                                                                                                <option
                                                                                                    value={stripeCountry.code}
                                                                                                    key={stripeCountry.code}>{stripeCountry.country}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    </Field>
                                                                                    <Field label="Phone (optional)"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="phone"
                                                                                               value={phone.value}
                                                                                               autoComplete="vatid"
                                                                                               changeHandler={setPhone}/>
                                                                                    </Field>
                                                                                </div>
                                                                                <div className="col-sm">
                                                                                    <Field label="Email"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="email"
                                                                                               name="email"
                                                                                               value={email.value}
                                                                                               required={true}
                                                                                               autoComplete="email"
                                                                                               changeHandler={setEmail}/>
                                                                                    </Field>
                                                                                    <Field
                                                                                        label="Address line 2 (optional)"
                                                                                        fieldRows="10"
                                                                                        labelRows="5">
                                                                                        <Input type="text"
                                                                                               name="line2"
                                                                                               value={line2.value}
                                                                                               autoComplete="line2"
                                                                                               changeHandler={setLine2}/>
                                                                                    </Field>
                                                                                    <Field label="City"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="city"
                                                                                               value={city.value}
                                                                                               required={true}
                                                                                               autoComplete="city"
                                                                                               changeHandler={setCity}/>
                                                                                    </Field>
                                                                                    <Field label="VAT ID (optional)"
                                                                                           fieldRows="10"
                                                                                           labelRows="4">
                                                                                        <Input type="text"
                                                                                               name="vatid"
                                                                                               value={vatId.value}
                                                                                               autoComplete="vatid"
                                                                                               changeHandler={changeVatId}/>
                                                                                    </Field>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <fieldset className="FormGroup">
                                                                    <div className="card-deck">
                                                                        <div className="card mb-4">
                                                                            <div
                                                                                className="card-header text-center">
                                                                                Credit or debit card
                                                                            </div>


                                                                            <div className="card-body">
                                                                                <div
                                                                                    className="row justify-content-md-center">
                                                                                    <div className="col col-12">
                                                                                        {cardError !== null &&
                                                                                        <Alert type="danger"
                                                                                               message={cardError}
                                                                                               dismissible={true}
                                                                                               onDismiss={() => setCardError(null)}></Alert>
                                                                                        }
                                                                                        <CardElement
                                                                                            options={CARD_ELEMENT_OPTIONS}></CardElement>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                                <div className="card-deck">
                                                                    <div className="card mb-4">
                                                                        <div className="card-header text-center">
                                                                            Summary
                                                                        </div>


                                                                        <div className="card-body">
                                                                            <div
                                                                                className="row justify-content-md-center">
                                                                                <div className="col col-12">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-sm font-weight-bold">
                                                                                            <h4>Selected
                                                                                                plan: {selectedPlan.name}</h4>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-sm text-right font-weight-bold">
                                                                                            {(reverseChargeCodesEu.includes(country) && vatIsValid) &&
                                                                                            <div>
                                                                                                <h4>€ {(selectedPlan.price / 100)}</h4>
                                                                                                reverse charge
                                                                                            </div>
                                                                                            }
                                                                                            {(!reverseChargeCodesEu.includes(country) || !vatIsValid) &&
                                                                                            <div>
                                                                                                <h4>€ {((selectedPlan.price / 100) + (selectedPlan.price / 100) * 0.19).toFixed(2)}</h4>
                                                                                                includes 19% ({((selectedPlan.price / 100) * 0.19).toFixed(2)}) VAT
                                                                                            </div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {errorMessage !== null &&
                                                                <Alert type="danger" message={errorMessage}
                                                                       dismissible={true}
                                                                       onDismiss={() => setErrorMessage(null)}></Alert>
                                                                }
                                                            </div>
                                                        </Form>
                                                    )}
                                                </div>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {(loading) ? (
                                <Loader text="loading plans..."></Loader>
                            ) : (
                                <div>No plan is found</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>

    )
}

export default Plans;
