import React, { useContext, useEffect } from "react";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Link } from "react-router-dom";

const Overview = () => {
    const title = 'Overview';

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    
    useEffect(() => {
        console.log(userData.currentMatomo);
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/matomo/"+userData.currentMatomo.id+"/",
                text: userData.currentMatomo.name,
                active: false
            },      
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [userData, setBreadcrumb, title]);

    return (
        <>
            <div className="container-fluid">
                <div className="animated fadeIn">
                    <div className="card">
                        <div className="card-header">
                            {userData.currentMatomo.name}
                        </div>
                        <div className="card-body">
                            <p><b>Matomo Instance host: {userData.currentMatomo.host}</b></p>
                            <Link to={'/matomo/'+userData.currentMatomo.id+'/update'} className="btn btn-primary mr-3">Edit</Link>
                            <Link to={'/matomo/'+userData.currentMatomo.id+'/delete'} className="btn btn-primary mr-3">Delete</Link>
                            {/*<Link to={'/matomo/'+userData.currentMatomo.id+'/users'} className="btn btn-primary mr-3">Manage Users</Link>*/}
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
}

export default Overview;
