import React, {useState,useContext, useEffect} from "react";
import { FirebaseAuth} from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field, Input } from '../../../../components/Form';
import { Redirect } from 'react-router-dom';
import Alert from "../../../../components/Alert";
import {AuthContext} from "../../../../components/FirebaseAuth";

const UpdateMatomo = () => {
    const title = 'Update Matomo Instance';

    const [matomoName, setMatomoName] = useState({
        hasError: false,
        error: null,
        value: ""
    });

    const [matomoApiKey, setMatomoApiKey] = useState({
        hasError: false,
        error: null,
        value: ""
    });

    const [matomoHost, setMatomoHost] = useState({
        hasError: false,
        error: null,
        value: ""
    });

    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);

    const { userData } = useContext(AuthContext);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/matomo/"+userData.currentMatomo.id+"/",
                text: userData.currentMatomo.name,
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
        setMatomoName({value: userData.currentMatomo.name});
        setMatomoApiKey({value: userData.currentMatomo.apikey});
        setMatomoHost({value: userData.currentMatomo.host});
        console.log(userData.currentMatomo.name)
    }, [userData, setBreadcrumb, title]);


    return (
        <>
            {redirect === null && 
            <>
                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            {errorMessage !== null && 
                                <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                            }
                            <div className="card-body">
                                <Form handleSubmit={e =>{
                                    e.preventDefault();
                                    setInSubmit(true);
                                    setErrorMessage(null);

                                    const matomoDoc = FirebaseAuth.firestore().doc('matomos/' + userData.currentMatomo.id);
                                    matomoDoc.update({"host": matomoHost.value,
                                    "name": matomoName.value,
                                    "apikey": matomoApiKey.value}).then(response => {
                                        // const matomoId = response.data.matomoId;
                                        setRedirect('/');
                                    }).catch(err => {
                                        setErrorMessage(err.message);
                                        setInSubmit(false);
                                    })
                                }}
                                disabled={matomoName.hasError || matomoName.value===null || matomoHost.hasError || matomoHost.value===null  || inSubmit}
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}>
                                    <Field label="Matomo Instance Name">
                                        <Input type="text" name="matomo-name" maxLen={100} value={matomoName.value}  required changeHandler={setMatomoName}/>
                                    </Field>
                                    <Field label="Matomo Host URL">
                                        <Input type="text" name="matomo-host" maxLen={100} value={matomoHost.value} required={true} changeHandler={setMatomoHost} />
                                    </Field>
                                    <Field label="Matomo API Key">
                                        <Input type="password" name="matomo-api-key" maxLen={100} value={matomoApiKey.value}  changeHandler={setMatomoApiKey} />
                                    </Field>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Redirect to={redirect}></Redirect>
            }
        </>

    )
}

export default UpdateMatomo;
