import React, {useContext, useState, useEffect} from "react";
import {BreadcrumbContext} from '../../../../components/Breadcrumb';
import {AuthContext} from "../../../../components/FirebaseAuth";
import {CloudFunctions, FirebaseAuth} from "../../../../components/FirebaseAuth/firebase";
import Alert from "../../../../components/Alert";
import {Redirect} from 'react-router-dom';
import PublicTemplate from "../../../../components/templates/PublicTemplate";
import Loader from "../../../../components/Loader";

const Billing = () => {

    const {userData} = useContext(AuthContext);
    const {setBreadcrumb} = useContext(BreadcrumbContext);

    const [errorMessage, setErrorMessage] = useState(null);

    const [loading, setLoading] = useState(true);

    const getUserPaymentData = () => {
        return FirebaseAuth.firestore().collection('users').doc(FirebaseAuth.auth().currentUser.uid).get().then(function (userDoc) {
                if (userDoc.exists) {
                    console.log(userDoc.data());
                    return userDoc.data()
                }
        });
    };

    const redirectToPortal = (redirect) => {
        window.location.assign(redirect);
    };

    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: "Billing",
                active: true
            }
        ]);
        getUserPaymentData().then(function (userData) {
            if ((userData.subscriptionStatus === "active") || userData.subscriptionStatus === "incomplete"
                || userData.subscriptionStatus === "past_due") {
                const createMatomo = CloudFunctions.httpsCallable('getCustomerPortalRedirect');
                createMatomo({}).then(response => {
                    console.log(response.data)
                    const redirectTarget = response.data.url;
                    redirectToPortal(redirectTarget)
                }).catch(err => {
                    setErrorMessage(err.message);
                    setLoading(false)
                })
            } else {
                setRedirect("/user/billing/plan");
                setLoading(false)
            }
        });

    }, [userData, setBreadcrumb]);


    return (
        <>
            {loading ? (
                <PublicTemplate>
                    <Loader size="5x" text="Loading..."/>
                </PublicTemplate>
            ):(
                <>
            {redirect === null &&
            <>
                <div className="container-fluid">
                    <div>
                        {errorMessage !== null &&
                        <Alert type="danger" message={errorMessage} dismissible={true}
                               onDismiss={() => setErrorMessage(null)}></Alert>
                        }
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Redirect to={redirect}></Redirect>
            }
            </>
                )}
        </>
    )
}

export default Billing;
