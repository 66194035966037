import React from "react";
import FirebaseUI from '../../../components/FirebaseUI';

const SignIn = () => {

    return (
        <>
            <div className="col-md-3"></div>
        
            <div className="text-center col-md-6">
                <div className="card">
                    <p></p>
                    VIU.one Data Studio Connector
                    <h2 className="h3 mb-3 font-weight-normal">Please sign in or register</h2>
                    <div className="card-body">
                        <FirebaseUI />
                    </div>
                    <a href="https://viu.one/matomo-data-studio-connector-terms-and-conditions/" target="_blank" rel="noopener noreferrer" id="terms_conditions_link">You accept Terms & Conditions by signing up.</a>
                </div>
            </div>

            <div className="col-md-3"></div>
            <div id="footer">
                <a href="https://viu.one/legal-information-data-protection/" target="_blank" rel="noopener noreferrer" >Legal information & data protection</a>
            </div>
        </>
    )
};

export default SignIn;
