import React, {useState, useContext, useEffect} from "react";
import { CloudFunctions } from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Form, Field, Input } from '../../../../components/Form';
import { Redirect } from 'react-router-dom';
import Alert from "../../../../components/Alert";

const NewMatomo = () => {
    const title = 'Create New Matomo Instance';

    const [matomoName, setMatomoName] = useState({
        hasError: false,
        error: null,
        value: null
    });

    const [matomoApiKey, setMatomoApiKey] = useState({
        hasError: false,
        error: null,
        value: null
    });

    const [matomoHost, setMatomoHost] = useState({
        hasError: false,
        error: null,
        value: null
    });

    const [errorMessage, setErrorMessage] = useState(null);

    const [inSubmit, setInSubmit] = useState(false);

    const [redirect, setRedirect] = useState(null);
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb, title]);


    return (
        <>
            {redirect === null && 
            <>
                <div className="container-fluid">
                    <div className="animated fadeIn">
                        <div className="card">
                            <div className="card-header">
                                {title}
                            </div>
                            {errorMessage !== null && 
                                <Alert type="danger" message={errorMessage} dismissible={true} onDismiss={() => setErrorMessage(null)}></Alert>
                            }
                            <div className="card-body">
                                <Form handleSubmit={e =>{
                                    e.preventDefault();
                                    setInSubmit(true);
                                    setErrorMessage(null);
                                    const createMatomo = CloudFunctions.httpsCallable('createMatomo');
                                    createMatomo({
                                        matomoName: matomoName.value,
                                        matomoApiKey: matomoApiKey.value,
                                        matomoHost: matomoHost.value
                                    }).then(response => {
                                        // const matomoId = response.data.matomoId;
                                        setRedirect('/');
                                    }).catch(err => {
                                        console.log(err)
                                        setErrorMessage(err.message);
                                        setInSubmit(false);
                                    })
                                }}
                                disabled={matomoName.hasError || matomoName.value===null || matomoHost.hasError || matomoHost.value===null  || inSubmit}
                                inSubmit={inSubmit}
                                enableDefaultButtons={true}>
                                    <Field label="Matomo Instance Name">
                                        <Input type="text" name="matomo-name" maxLen={100} required={true} changeHandler={setMatomoName}  aria-describedby="matomoNameHelp"/>
                                        <small id="matomoNameHelp" className="form-text text-muted">
                                            A descriptive name for the instance.
                                        </small>
                                    </Field>
                                    <Field label="Matomo Host URL">
                                        <Input type="text" name="matomo-host" maxLen={100} required={true} changeHandler={setMatomoHost}  aria-describedby="matomoHost"/>
                                        <small id="matomoNameHelp" className="form-text text-muted">
                                            The host of your Matomo instance e.g. https://demo.matomo.cloud/
                                        </small>
                                    </Field>
                                    <Field label="Matomo API Key">
                                        <Input type="password" name="matomo-api-key" maxLen={100} changeHandler={setMatomoApiKey}  aria-describedby="matomoAPIKey"/>
                                        <small id="matomoNameHelp" className="form-text text-muted">
                                            The Matomo API Key. You will find it in Settings => Personal => Security => Auth Token => Create API Token
                                        </small>
                                    </Field>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
            {redirect !== null &&
                <Redirect to={redirect}></Redirect>
            }
        </>

    )
}

export default NewMatomo;
