import React from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthProvider } from './components/FirebaseAuth';

import PublicRouter from './components/routers/PublicRouter';
import PublicTemplate from './components/templates/PublicTemplate';
import ActiveMatomoTemplate from './components/templates/ActiveMatomoTemplate';

import AuthRouter from './components/routers/AuthRouter';

import SignIn from './pages/public/SignIn';
import Home from './pages/auth/Home';
import PageNotFound from './pages/public/PageNotFound';
import AppTemplate from './components/templates/AppTemplate';
import UserProfile from './pages/auth/user/UserProfile';
import UpdateEmail from './pages/auth/user/UpdateEmail';
import UpdateName from './pages/auth/user/UpdateName';
import VerifyEmail from './pages/auth/user/VerifyEmail';
import UpdatePassword from './pages/auth/user/UpdatePassword';
import UpdatePhone from './pages/auth/user/UpdatePhone';
import DeleteUser from './pages/auth/user/DeleteUser';
import ViewLogs from './pages/auth/user/ViewLogs';
import Plans from './pages/auth/user/Plans';
import NewMatomo from './pages/auth/matomos/NewMatomo';
import DeleteMatomo from './pages/auth/matomos/DeleteMatomo';
import UpdateMatomo from './pages/auth/matomos/UpdateMatomo';

// load stripe
import { stripeConfig } from "./inc/stripe.json";
import { Elements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Overview from './pages/auth/matomos/Overview';
import UserList from './pages/auth/matomos/UserList';
import UserRole from './pages/auth/matomos/UserRole';
import AddUser from './pages/auth/matomos/AddUser';
import Invite from './pages/auth/user/Invite';
import Billing from './pages/auth/user/Billing';

const stripePromise = loadStripe(stripeConfig.public_api_key);


function App() {
	return (
		<Elements stripe={stripePromise}>
			<AuthProvider>
				<Router>
					<Switch>
						<AuthRouter exact path="/" component={Home} template={AppTemplate} title="Matomo instances" />
						<AuthRouter exact path="/matomo/:matomoId/users/change/:userId" component={UserRole} template={ActiveMatomoTemplate} title="Change Role" role="admin" />
						<AuthRouter exact path="/matomo/:matomoId/users" component={UserList} template={ActiveMatomoTemplate} title="Users" role="admin" />
						<AuthRouter exact path="/matomo/:matomoId/users/add" component={AddUser} template={ActiveMatomoTemplate} title="Add User" role="admin" />
						<AuthRouter exact path="/matomo/:matomoId/" component={Overview} template={ActiveMatomoTemplate} title="Overview" role="*" />
						<AuthRouter exact path="/matomo/:matomoId/delete" component={DeleteMatomo} template={ActiveMatomoTemplate} title="Delete Matomo"  role="admin"/>
						<AuthRouter exact path="/matomo/:matomoId/update" component={UpdateMatomo} template={ActiveMatomoTemplate} title="Update Matomo"  role="admin"/>
						<AuthRouter exact path="/new-matomo" component={NewMatomo} template={AppTemplate} title="Create New Matomo Instance" />

						<AuthRouter exact path="/user/profile" component={UserProfile} template={AppTemplate} title="User Profile" />
						<AuthRouter exact path="/invite/:code" component={Invite} template={AppTemplate} title="View Invite" />
						<AuthRouter exact path="/user/profile/update-email" component={UpdateEmail} template={AppTemplate} title="Change Your Email" />
						<AuthRouter exact path="/user/profile/update-name" component={UpdateName} template={AppTemplate} title="Change Your Name" />
						<AuthRouter exact path="/user/profile/verify-email" component={VerifyEmail} template={AppTemplate} title="Verify Your Name" />
						<AuthRouter exact path="/user/profile/update-password" component={UpdatePassword} template={AppTemplate} title="Change Your Password" />
						<AuthRouter exact path="/user/profile/update-phone" component={UpdatePhone} template={AppTemplate} title="Change Your Phone Number" />
						<AuthRouter exact path="/user/profile/delete" component={DeleteUser} template={AppTemplate} title="Delete Your Matomo" />

						<AuthRouter exact path="/user/billing/plan" component={Plans} template={AppTemplate} title="Select Plan" />
						<AuthRouter exact path="/user/billing" component={Billing} template={AppTemplate} title="Billing" role="admin" />

						<AuthRouter exact path="/user/log" component={ViewLogs} template={AppTemplate} title="View Activity Logs" />
						<PublicRouter exact path="/signin" component={SignIn} template={PublicTemplate} title="Sign In" />
						<PublicRouter component={PageNotFound} template={PublicTemplate} title="Page Not Found" />
					</Switch>
				</Router>
			</AuthProvider>
		</Elements>
	);
}

export default App;
